<template>
  <header class="mx-2.5 lg:mx-5 mb-2.5 lg:mb-7 z-50 relative md:h-[5rem] h-14">
    <ui-container
      spacing="none"
      no-gutters-x
    >
      <ui-card
        class="flex items-center md:gap-3 gap-2 pr-5 lg:pr-7"
        padding="custom"
      >
        <ui-logo />
        <common-headers-set-them class="ml-auto" />
      </ui-card>
    </ui-container>
  </header>
</template>
<script setup lang="ts"></script>
