<template>
  <ui-body-wrap>
    <common-headers-policy />
    <main class="relative flex flex-1 px-2.5 lg:px-5 min-h-52 overflow-auto max-h-screen">
      <ui-container
        spacing="none"
        no-gutters-x
      >
        <ui-card
          padding="custom"
          class="p-2.5 pt-2 lg:py-12 lg:pt-4 lg:px-7"
        >
          <slot />
        </ui-card>
      </ui-container>
    </main>
  </ui-body-wrap>
</template>
